<template>
  <div v-if="loading === true" id="spinner">
    <CSpinner color="primary" />
    <div>{{ $t("VERIFICATION.VERIFYING_USER") }}</div>
  </div>
</template>
<script>
import { VerifyMerchant } from "@/api/merchant_verification.api.js";
import { contactUrl } from "@/../config.js";

export default {
  name: "UserVerificationView",
  data() {
    return {
      loading: false,
      token: null,
    };
  },
  methods: {
    async verifyMerchant() {
      try {
        this.loading = true;
        const response = await VerifyMerchant(this.token);
        this.$router.push("/");
        if (response.data.data.AlreadyVerified === true) {
          this.showSuccessToaster(this.$t("SIGN_UP.VERIFICATION.ALREADY_VERIFIED"));
        } else {
          this.showSuccessToaster(this.$t("SIGN_UP.VERIFICATION.SUCCESS"));
        }
      } catch (error) {
        this.showToaster(this.$t("SIGN_UP.VERIFICATION.FAILURE"));
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.token = this.$route.query.verification_token;
    this.verifyMerchant();
  },
};
</script>

<style scoped>
#spinner {
  margin-left: 48%;
  margin-top: 25%;
}
</style>
