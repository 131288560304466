import { httpClient } from './httpClient';

const VERIFYMERCHANTWITHTOKEN = '/dashboard/users/verify';

const VerifyMerchant = async (token) => {
  const headers = { 'verification_token': token };
  const data = await httpClient.dashboardService.post(VERIFYMERCHANTWITHTOKEN, {}, { headers: headers });
  return data;
}

export { VerifyMerchant };